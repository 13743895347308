import { Container } from "@mui/material";
import { graphql } from "gatsby";
import React from "react";
import RenderMarkdown from "../cms/RenderMarkdown";
import IntroSection from "../components/public/IntroSection";
import PublicLayout from "../layout/PublicLayout";

type PrivacyPolicyTemplateProps = {
  content: string;
  preview?: boolean;
};

export const PrivacyPolicyTemplate: React.FC<PrivacyPolicyTemplateProps> = ({
  preview,
  content = ""
}) => {
  return <RenderMarkdown runtime={preview}>{content}</RenderMarkdown>;
};

type PrivacyPolicyProps = {
  data: any;
};

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({ data }) => {
  return (
    <PublicLayout seoTitle="Privacy Policy">
      <IntroSection title="Privacy Policy" />
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <PrivacyPolicyTemplate content={data.mdx.body} />
      </Container>
    </PublicLayout>
  );
};

export const privacyPageQuery = graphql`
  query PrivacyPolicyPage($id: String!) {
    mdx(id: { eq: $id }) {
      body
    }
  }
`;

export default PrivacyPolicy;
